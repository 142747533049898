import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
  renameObjKeys,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById, getUsersById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { Button, Footer, H3, H5, ModalInMobile, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { getAllMerchats, setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { subCatergoriesOptions } from '../../config/configListing';
import {listingfilter} from '../../config/configListing';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
    const { config, subCatergoriesOptions } = this.props;

    if (
      subCatergoriesOptions &&
      subCatergoriesOptions.length &&
      config &&
     listingfilter &&
     listingfilter &&
     listingfilter.length
    ) {
     listingfilter.map(field => {
        if (field.key == 'subCategories') {
          Object.assign(field, {
            enumOptions: subCatergoriesOptions,
          });
        }
      });
    }
  }
  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter, params } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      // const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingfilter, defaultFiltersConfig),
      };
      const { type = 'shop-by-merchants' } = params;
      history.push(createResourceLocatorString('SearchPage', routes, { type }, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  handleSubmit(values) {
    const { currentSearchParams, params } = this.props;
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };

    const { type = 'shop-by-merchants' } = params;
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration, { type }, searchParams)
    );
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config, params } = this.props;
    // const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingfilter,
      defaultFiltersConfig,
      sortConfig
    );

    let { type = 'shop-by-merchants' } = params;
    if (search.pub_category) {
      type = search.pub_category;
      // search.pub_category = null;
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, { type }, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config, params } = this.props;
    // const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingfilter, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);

    const { type = 'shop-by-merchants' } = params;
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration, { type }, queryParams)
    );
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config, params } = this.props;
    // const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingfilter,
            defaultFiltersConfig,
            sortConfig
          );
          let { type = 'shop-by-merchants' } = params;
          if (search.pub_category) {
            type = search.pub_category;
            // search.pub_category = null;
          }
          history.push(
            createResourceLocatorString('SearchPage', routeConfiguration, { type }, search)
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration, params } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    const { type = 'shop-by-merchants' } = params;
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration, { type }, queryParams)
    );
  }

  render() {
    const {
      intl,
      params,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      merchants,
      onFetchMerchant,
      history,
      bookmarkedData,
      isAuthenticated,
      currentUser,
      subCatergoriesOptions,
      // allListings
    } = this.props;

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(
      location?.search,
      {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      }
    );
    // const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    const topbarSearcInitialValues = () => {
      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingfilter,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingfilter,
      defaultFiltersConfig,
      false
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingfilter,
      activeListingTypes
    );



    const availablePrimaryFilters = [
      ...customPrimaryFilters,
      // ...defaultFilters
    ];
    const availableFilters = [
      ...customPrimaryFilters,
      // ...defaultFilters,
      ...customSecondaryFilters,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingfilter,
      defaultFiltersConfig
    );
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      // searchParamsAreInSync &&
      hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      // searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingfilter,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    // const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    let subCategoryOptions = [],
      availableFilterss = [],
      customSecondaryFilterss = [];

    listings && Array.isArray(listings) && listings.length
      ? listings
          .filter(
            st =>
              st.attributes.publicData.listingType == 'merchant' ||
              st.currentStock?.attributes?.quantity > 0
          )
          .map(listing => {
            const { listingSubCategory, subCategories = [] } = listing.attributes.publicData || {};
            if (subCategories && subCategories.length && Array.isArray(subCategories)) {
              subCategoryOptions.push(
                ...subCatergoriesOptions.filter(s => subCategories.includes(s.option))
              );
              if (availableFilterss.length == 0) {
                availableFilterss = availableFilters.filter(af => af.key != 'listingSubCategory');
                customSecondaryFilterss = customSecondaryFilters.filter(
                  af => af.key != 'listingSubCategory'
                );
              }
            } else if (listingSubCategory && typeof listingSubCategory == 'string') {
              subCategoryOptions.push(
                ...subCatergoriesOptions.filter(s => listingSubCategory == s.option)
              );
              if (availableFilterss.length == 0) {
                availableFilterss = availableFilters.filter(af => af.key != 'subCategories');
                customSecondaryFilterss = customSecondaryFilters.filter(
                  af => af.key != 'subCategories'
                );
              }
            }
          })
      : [];

    subCategoryOptions = subCategoryOptions
      .filter((value, index, self) => index === self.findIndex(t => t.option === value.option))
      .map(d => ({ ...d, key: d.option }));
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <div className={css.searchResultContainer}>
            <SearchFiltersMobile
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              isMapVariant
            >
              {availableFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersMobile.${config.key}`}
                    idPrefix="SearchFiltersMobile"
                    config={config}
                    marketplaceCurrency={marketplaceCurrency}
                    params={params}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    productListings={listings}
                    liveEdit
                    showAsPopup={false}
                    subCategoryOptions={subCategoryOptions}
                  />
                );
              })}
            </SearchFiltersMobile>
            <MainPanelHeader
              className={css.mainPanelMapVariant}
              sortByComponent={sortBy('desktop')}
              isSortByActive={sortConfig.active}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              noResultsInfo={noResultsInfo}
            >
              <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                {availablePrimaryFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersPrimary.${config.key}`}
                      idPrefix="SearchFiltersPrimary"
                      config={config}
                      params={params}
                      onFetchMerchant={onFetchMerchant}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      productListings={listings}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      subCategoryOptions={subCategoryOptions}
                    />
                  );
                })}
              </SearchFiltersPrimary>
            </MainPanelHeader>
            {/* <div className={css.searchBarBox}>
              <TopbarSearchForm
                className={css.searchLink}
                desktopInputRoot={css.topbarSearchWithLeftPadding}
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                appConfig={config}
              />
            </div> */}

            {/* {isSecondaryFiltersOpen ?  */}
            {/* ( */}
            <div className={classNames(css.searchFiltersPanel)}>
              {params?.type == 'Coupon' ? null : (
                <div className={css.leftCategory}>
                  <SearchFiltersSecondary
                    urlQueryParams={validQueryParams}
                    listingsAreLoaded={listingsAreLoaded}
                    applyFilters={this.applyFilters}
                    cancelFilters={this.cancelFilters}
                    resetAll={this.resetAll}
                    params={params}
                    onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                  >
                   {customSecondaryFilterss.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersSecondary.${config.key}`}
                          idPrefix="SearchFiltersSecondary"
                          config={config}
                          params={params}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          productListings={listings}
                          showAsPopup={false}
                          subCategoryOptions={subCategoryOptions}
                        />
                      );
                    })}
                  </SearchFiltersSecondary>
                </div>
              )}
              <div className={css.searchPageRight}>
                <div
                  className={classNames(css.listingsForMapVariant, {
                    [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                  })}
                >
                  {searchListingsError ? (
                    <H3 className={css.error}>
                      <FormattedMessage id="SearchPage.searchError" />
                    </H3>
                  ) : null}
                  {!isValidDatesFilter ? (
                    <H5>
                      <FormattedMessage id="SearchPage.invalidDatesFilter" />
                    </H5>
                  ) : null}
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    merchants={merchants}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    location={location}
                    setActiveListing={onActivateListing}
                    bookmarkedData={bookmarkedData}
                    isAuthenticated={isAuthenticated}
                    currentUser={currentUser}
                    isMapVariant
                    params={params}
                    marketplaceCurrency={marketplaceCurrency}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentMerchantResultIds,
    customPageResultIds,
    filterProducts,
    subCatergoriesOptions,
  } = state.SearchPage;

  const listings = getListingsById(state, currentPageResultIds);
  const merchants = getUsersById(state, currentMerchantResultIds);
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;

  return {
    listings,
    merchants,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
    currentUser,
    filterProducts,
    subCatergoriesOptions,
    // allListings
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchMerchant: () => dispatch(getAllMerchats()),
  bookmarkedData: id => dispatch(updateProfile(id)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedSearchPage);

export default SearchPage;
